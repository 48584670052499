const headerMenu = function (header: string, btn: string) {
  type pos = {
    x: number;
    y: number;
  };
  type elementInfo = {
    name: string;
    dom: HTMLElement;
    domClone: HTMLElement;
    x: number;
    y: number;
  };
  const headerNav = document.querySelector<HTMLElement>(`.header-nav`);
  const headerDOM = document.querySelector<HTMLElement>(header);
  const btnDOM = headerDOM?.querySelector<HTMLElement>(btn);
  const cloneTarget: string[] = [
    `menu-window`,
    `header-nav__button`,
    `header-logo`,
  ];
  const cloneTargetInfo = [];
  const clipDOM = document.createElement(`div`);
  const domClone = {};
  const delay = 10;
  const circlePos: pos = {
    x: 0,
    y: 0,
  };
  const mouseClient: pos = {
    x: 0,
    y: 0,
  };
  let updateRequest;
  let mouseX = 0;
  let mouseY = 0;
  let targetSize = 11.2;
  let currentSize = 0;
  const stickPos: pos = {
    x: 0,
    y: 0,
  };
  const ua = navigator.userAgent.toLowerCase();
  if (
    /android|ipod|ipad|iphone|macintosh/.test(ua) &&
    `ontouchend` in document
  ) {
    btnDOM?.addEventListener(`click`, () => {
      if (headerDOM?.classList.contains(`is-open`)) {
        headerDOM?.classList.remove(`is-open`);
      } else {
        headerDOM?.classList.add(`is-open`);
      }
    });
    return;
  }
  for (const target of cloneTarget) {
    const targetInfo: elementInfo = {
      name: undefined,
      dom: undefined,
      domClone: undefined,
      x: 0,
      y: 0,
    };
    targetInfo.name = target;
    targetInfo.dom = document.querySelector<HTMLElement>(`.${target}`);
    const rect = targetInfo.dom.getBoundingClientRect();
    targetInfo.x = rect.left;
    targetInfo.y = rect.top;
    if (target === `header-nav__button`) {
      stickPos.x = rect.left + rect.width / 2;
      stickPos.y = rect.top + rect.height / 2;
    }
    targetInfo.domClone = <HTMLElement>targetInfo.dom.cloneNode(true);
    cloneTargetInfo.push(targetInfo);
    clipDOM.appendChild(targetInfo.domClone);
  }
  clipDOM.classList.add(`header-menu-clip`);
  headerNav.appendChild(clipDOM);

  let hoverActive = true;
  let stick = false;

  const initRect = () => {
    mouseX = stickPos.x;
    mouseY = stickPos.y;
    circlePos.x = stickPos.x;
    circlePos.y = stickPos.y;
  };

  const getRect = () => {
    for (const target of cloneTargetInfo) {
      const rect = target.dom.getBoundingClientRect();
      target.x = rect.left;
      target.y = rect.top;
      if (target.name === `header-nav__button`) {
        stickPos.x = rect.left + rect.width / 2;
        stickPos.y = rect.top + rect.height / 2;
      }
    }
  };

  const move = (e) => {
    if (stick) {
      mouseX = stickPos.x;
      mouseY = stickPos.y;
      return;
    }
    mouseX = e.clientX;
    mouseY = e.clientY;
  };

  const update = () => {
    let pxToRemDivision = 10;
    if (window.innerWidth > 1700) pxToRemDivision = 11;
    circlePos.x += (mouseX - circlePos.x) / delay;
    circlePos.y += (mouseY - circlePos.y) / delay;
    currentSize += (targetSize - currentSize) / delay;
    if (hoverActive) {
      for (const target of cloneTargetInfo) {
        target.domClone.setAttribute(
          `style`,
          `clip-path:circle(${currentSize}rem at ${
            (circlePos.x - target.x) / pxToRemDivision
          }rem ${(circlePos.y - target.y) / pxToRemDivision}rem);`,
        );
      }
    }
    updateRequest = window.requestAnimationFrame(update);
  };

  btnDOM?.addEventListener(`click`, () => {
    if (headerDOM?.classList.contains(`is-open`)) {
      headerDOM?.classList.remove(`is-open`);
      window.cancelAnimationFrame(updateRequest);
      window.removeEventListener(`mousemove`, move, false);
    } else {
      headerDOM?.classList.add(`is-open`);
      getRect();
      initRect();
      update();
      window.addEventListener(`mousemove`, move, false);
    }
  });

  btnDOM?.addEventListener(`mouseover`, () => {
    const style = document.createElement(`style`);
    style.setAttribute(`id`, `js-header-btn-hover-style`);
    style.innerText = `@media ( min-width: 981px ){.js-header-btn::before { background-color:#fff } .js-header-btn::after { background-color:#fff }}`;
    btnDOM.append(style);
  });

  btnDOM?.addEventListener(`mouseout`, () => {
    document.getElementById(`js-header-btn-hover-style`).remove();
  });

  window.addEventListener(`scroll`, () => {
    if (!hoverActive) return;
    getRect();
  });

  cloneTargetInfo[1].dom.addEventListener(`mouseover`, () => {
    if (!hoverActive) return;
    targetSize = 5.0;
    stick = true;
  });
  cloneTargetInfo[1].dom.addEventListener(`mouseout`, () => {
    if (!hoverActive) return;
    targetSize = 11.2;
    stick = false;
  });

  const onWindowResize = () => {
    if (window.innerWidth < 960) {
      targetSize = 0;
      hoverActive = false;
      clipDOM.classList.remove(`is-show`);
      return;
    }
    getRect();
    initRect();
    clipDOM.classList.add(`is-show`);
    hoverActive = true;
  };

  window.addEventListener(`resize`, onWindowResize);
  onWindowResize();
};

export default headerMenu;
