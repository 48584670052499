import React from 'react';
import Layout from '@/components/Layout';
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';
import '@/styles/screen.scss';
import { CookiesProvider } from 'react-cookie';

export const wrapPageElement = ({ element }) => (
  <>
    <CookiesProvider>
      <Layout>{element}</Layout>
    </CookiesProvider>
  </>
);
