// ■コンポーネント説明
// ヘッダー

import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import headerMenu from '@/components/Interaction/header-menu';
import menuSP from '@/components/Interaction/menu-sp';
import imgSprite from '@/assets/images/common/symbol-defs.svg';

require(`svgxuse`); // SVGスプライトのIE11対応

const scrollTop = (): number =>
  Math.max(
    window.scrollY,
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop,
  );

const Header: React.FC = () => {
  const [scrollHeight, setScrollHeight] = useState(scrollTop());
  const [isRunning, setIsRunning] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  function ToggleIsOpen() {
    setIsOpen(!isOpen);
  }

  function RemoveIsOpen() {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  }

  function logit() {
    if (!isRunning) {
      setIsRunning(true);
      window.requestAnimationFrame(() => {
        setScrollHeight(scrollTop());
        setIsRunning(false);
      });
    }
  }

  useEffect(() => {
    headerMenu(`.js-header`, `.js-header-btn`);
    menuSP(
      `.js-menu-sp`,
      `.js-menu-sp__button`,
      `.js-menu-sp__wrap`,
      `.js-menu-sp__inner`,
    );
    function watchScroll() {
      window.addEventListener(`scroll`, logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener(`scroll`, logit);
    };
  }, []);

  const targetDOM = document.querySelector<HTMLElement>(`#news`);
  const marginOffset = targetDOM
    ? scrollHeight + targetDOM.getBoundingClientRect().top
    : 0;
  const offset = targetDOM ? scrollHeight + window.innerHeight : scrollHeight;

  return (
    <>
      <header
        className={`header-wrap js-header${
          offset > marginOffset ? ` is-scroll` : ``
        }${isOpen ? ` is-open` : ``}`}
      >
        <h1 className="header-logo">
          <Link to="/" onClick={RemoveIsOpen}>
            <svg
              role="img"
              aria-label="NRI Netcom"
              className="header-logo__icon"
            >
              <use href={`${imgSprite}#img_logo`} />
            </svg>
          </Link>
        </h1>
        <nav className="header-nav">
          <ul className="header-nav__bar header-nav-list">
            <li className="header-nav-list__item">
              <Link
                to="/service/web-solution/"
                className="nav-target js-hover-circle"
                data-circle-type="reverse-word"
              >
                Web Solution
              </Link>
            </li>
            <li className="header-nav-list__item">
              <Link
                to="/service/web-creative/"
                className="nav-target js-hover-circle"
                data-circle-type="reverse-word"
              >
                Web Creative
              </Link>
            </li>
            <li className="header-nav-list__item">
              <Link
                to="/service/digital-marketing/"
                className="nav-target js-hover-circle"
                data-circle-type="reverse-word"
              >
                Digital Marketing
              </Link>
            </li>
            <li className="header-nav-list__item">
              <Link
                to="/service/cloud-solution/"
                className="nav-target js-hover-circle"
                data-circle-type="reverse-word"
              >
                Cloud Solution
              </Link>
            </li>
            <li className="header-nav-list__item">
              <Link
                to="/company/"
                className="nav-target js-hover-circle"
                data-circle-type="reverse-word"
              >
                About Netcom
              </Link>
            </li>
            <li className="header-nav-list__item --recruit">
              <a
                href="/recruit/"
                target="_blank"
                className="nav-target js-hover-circle"
                data-circle-type="reverse-word"
              >
                Recruit
              </a>
            </li>
          </ul>
          <div className="header-nav__button">
            <button
              className="header-button js-header-btn"
              type="button"
              onClick={ToggleIsOpen}
            >
              <div className="header-button__inner">メニュー</div>
            </button>
          </div>
          <div className="header-nav__window menu-window">
            <div className="menu-window__overflow">
              <ul className="menu-window__inner menu-list">
                <li className="menu-list__item js-menu-sp">
                  <Link
                    to="/company/"
                    className="menu-target"
                    onClick={ToggleIsOpen}
                  >
                    About Netcom
                  </Link>
                  <button
                    type="button"
                    className="menu-accordion-button js-menu-sp__button"
                  >
                    開く/閉じる
                  </button>
                  <div className="menu-list-sub-wrap js-menu-sp__wrap">
                    <ul className="menu-list-sub js-menu-sp__inner">
                      <li className="menu-list-sub__item">
                        <Link
                          to="/company/message/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Message
                        </Link>
                      </li>
                      <li className="menu-list-sub__item">
                        <Link
                          to="/company/profile/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Profile
                        </Link>
                      </li>
                      <li className="menu-list-sub__item">
                        <Link
                          to="/company/nri-group/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          NRI Group
                        </Link>
                      </li>
                      <li className="menu-list-sub__item">
                        <Link
                          to="/company/office/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Office
                        </Link>
                      </li>
                      <li className="menu-list-sub__item">
                        <Link
                          to="/company/sustainability/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Sustainability
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="menu-list__item js-menu-sp">
                  <Link
                    to="/service/"
                    className="menu-target"
                    onClick={ToggleIsOpen}
                  >
                    Service
                  </Link>
                  <button
                    type="button"
                    className="menu-accordion-button js-menu-sp__button"
                  >
                    開く/閉じる
                  </button>
                  <div className="menu-list-sub-wrap js-menu-sp__wrap">
                    <ul className="menu-list-sub js-menu-sp__inner">
                      <li className="menu-list-sub__item">
                        <Link
                          to="/service/web-solution/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Web Solution
                        </Link>
                      </li>
                      <li className="menu-list-sub__item">
                        <Link
                          to="/service/web-creative/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Web Creative
                        </Link>
                      </li>
                      <li className="menu-list-sub__item">
                        <Link
                          to="/service/digital-marketing/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Digital Marketing
                        </Link>
                      </li>
                      <li className="menu-list-sub__item">
                        <Link
                          to="/service/cloud-solution/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Cloud Solution
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="menu-list__item js-menu-sp">
                  <Link
                    to="/works/"
                    className="menu-target"
                    onClick={ToggleIsOpen}
                  >
                    Works
                  </Link>
                  <button
                    type="button"
                    className="menu-accordion-button js-menu-sp__button"
                  >
                    開く/閉じる
                  </button>
                  <div className="menu-list-sub-wrap js-menu-sp__wrap">
                    <ul className="menu-list-sub js-menu-sp__inner">
                      <li className="menu-list-sub__item">
                        <Link
                          to="/cases/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Case Study
                        </Link>
                      </li>
                      <li className="menu-list-sub__item">
                        <Link
                          to="/books/"
                          className="menu-target-sub"
                          onClick={ToggleIsOpen}
                        >
                          Books
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <div className="menu-list__pc-rows">
                  <li className="menu-list__item">
                    <Link
                      to="/news/"
                      className="menu-target"
                      onClick={ToggleIsOpen}
                    >
                      News
                    </Link>
                  </li>
                  <li className="menu-list__item">
                    <Link
                      to="/contact/"
                      className="menu-target"
                      onClick={ToggleIsOpen}
                    >
                      Contact
                    </Link>
                  </li>
                  <li className="menu-list__item">
                    <a href="/recruit/" className="menu-target" target="_blank">
                      Recruit
                    </a>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
