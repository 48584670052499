// ■コンポーネント説明
// 全体レイアウト調整用コンポーネント

import React, { createContext, Suspense, lazy, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import Header from '@/components/Header';
const Footer = lazy(() => import('@/components/Footer'));

export interface LayoutProps {
  location?: {
    pathname: string;
  };
  children: any;
}

export const Context = createContext(true);

const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  const ua = window.navigator.userAgent.toUpperCase();
  const location = useLocation();
  const { children } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    if (location.pathname !== `/ie.html` && !(ua.indexOf(`MSIE`) === -1 && ua.indexOf(`TRIDENT`) === -1)) window.location.href = `/ie.html`;
  }, []);

  return (
    <>
      {!loading && (
        <>
          <Header />
          <Context.Provider value={loading}>
            <main>{children}</main>
          </Context.Provider>
          <Suspense fallback={null}>
            <Footer />
          </Suspense>
        </>
      )}
    </>
  );
};

export default Layout;
