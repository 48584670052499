const menuSP = function (
  parent: string,
  button: string,
  wrap: string,
  inner: string,
) {
  const menuNodeList = document.querySelectorAll<HTMLElement>(parent);
  const bkPoint = 980;
  let isRunning = false;
  let flag = true;

  menuNodeList?.forEach((element) => {
    const buttonDOM = element.querySelector<HTMLElement>(button);
    const wrapDOM = element.querySelector<HTMLElement>(wrap);
    const innerDOM = element.querySelector<HTMLElement>(inner);
    buttonDOM.addEventListener(`click`, () => {
      if (!buttonDOM.classList.contains(`is-open`)) {
        const innerHeight = innerDOM.offsetHeight;
        wrapDOM.setAttribute(`style`, `height: ${innerHeight / 10}rem`);
        buttonDOM.classList.add(`is-open`);
      } else {
        wrapDOM.removeAttribute(`style`);
        buttonDOM.classList.remove(`is-open`);
      }
    });
  });

  window.addEventListener(`resize`, () => {
    if (!isRunning) {
      isRunning = true;
      window.requestAnimationFrame(() => {
        if (window.innerWidth > bkPoint && flag) {
          flag = false;
          menuNodeList?.forEach((element) => {
            const buttonDOM = element.querySelector<HTMLElement>(button);
            const wrapDOM = element.querySelector<HTMLElement>(wrap);
            wrapDOM.removeAttribute(`style`);
            buttonDOM.classList.remove(`is-open`);
          });
        } else if (window.innerWidth < bkPoint && !flag) {
          flag = true;
        }
        isRunning = false;
      });
    }
  });
};

export default menuSP;
